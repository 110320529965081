(function (window,document,$) {
   window.techsmith = window.techsmith || { };
   window.techsmith.scrollIntoView = function (element, scrollTopOffset, minimumMargin, duration) {

      var $win = $(window);
      var winHeight = $win.height() - scrollTopOffset;

      var scrollTop = $win.scrollTop() + scrollTopOffset;
      var scrollBottom = winHeight + scrollTop;
      var elOffset = $(element).offset();
      var desiredTop = elOffset.top - minimumMargin;
      var desiredBottom = (elOffset.top + $(element).height() + minimumMargin);

      var requiredScroll = 0;

      // off the top edge
      if (desiredTop < scrollTop) {
         requiredScroll = desiredTop - scrollTop;
      }
         // off the bottom edge
      else if (desiredBottom > scrollBottom) {
         requiredScroll = desiredBottom - scrollBottom;
      }

      var newScrollPos = $win.scrollTop() + requiredScroll;
      if (typeof duration === "undefined") {
         duration = 0;
      }
      $(document).scrollTo(newScrollPos, { axis: 'y', duration: duration });
   };
}(window,document,jQuery))