(function(window, $, Backbone) {
   window.techsmith = window.techsmith || {};
   window.techsmith.MessageView = Backbone.View.extend({
      className: "error-message",
      model: techsmith.MessageModel,
      initialize: function () {
         Handlebars.registerHelper('message', function () {
            return new Handlebars.SafeString(this.message);
         });
         this.template = Handlebars.templates.message;
      },
      render: function() {
         this.$el.html(this.template(this.model.attributes));
         return this;
      }
   });
}(window, $, Backbone));