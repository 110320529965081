(function (window, $) {
   window.techsmith = window.techsmith || {};
   window.techsmith.QuestionConstants = (function () {

      var _transitionEvent;

      function whichTransitionEvent() {
         var transition;
         var el = document.createElement('fakeelement');
         var transitions = {
            'OTransition': 'oTransitionEnd',
            'MSTransition': 'msTransitionEnd',
            'msTransition': 'transitionend',
            'MozTransition': 'transitionend',
            'WebkitTransition': 'webkitTransitionEnd',
            'transition': 'transitionEnd'
         };

         for (transition in transitions) {
            if (el.style[transition] !== undefined) {
               return transitions[transition];
            }
         }
         return undefined;
      }

      _transitionEvent = whichTransitionEvent();

      return {
         Type: {
            FillInTheBlank: "FillInTheBlank",
            ShortAnswer: "ShortAnswer",
            MultipleChoice: "MultipleChoice",
            TrueFalse: "TrueFalse"
         },
         MaxAllowedCharacters: 750,
         NoTouchClass: "no-touch",
         TransitionEvent: _transitionEvent
      };
   })();
}(window, jQuery))