(function (window) {

   "use strict";

   window.UploadMediaview = Backbone.View.extend({

      events: {
         "dragenter #uploadArea": "noopHandler",
         "dragexit #uploadArea": "noopHandler",
         "dragover #uploadArea": "noopHandler",
         "drop #uploadArea": "drop"
      },

      initialize: function () {
         this.render();
      },
      
      handleReaderLoad: function(e) {
          
          var url = [location.protocol, '//', location.host, location.pathname].join('');
          url = url + '/UploadFile';
       
          //$.post(url, { fileData: e.target.result });

          //var formData = new FormData();
          //formData.fileData = e.target.result;
          
          $.ajax({
              url: url,
              data: e.target.result,
              cache: false,
              contentType: "multipart/form-data",
              processData: false,
              type: 'POST',
              success: function (data) {
                 techsmith.notify.alert(data);
              }
          });
          
          
          //$.post(url, { fileData: e.target.result });
          //var data = new FormData();
          //data.append('fileData', e.target.result);
          
      },

      handleFiles: function(files) {

         var file = files[0];

         var reader = new FileReader();

         // init the reader event handlers
         reader.onload = this.handleReaderLoad;

         // begin the read operation
          //reader.readAsDataURL(file);
          //reader.readAsArrayBuffer(file);
          reader.readAsBinaryString(file);

      },

      noopHandler: function (e) {
         e.stopPropagation();
         e.preventDefault();
      },

      drop: function (e) {

         e.stopPropagation();
         e.preventDefault();

         var files = e.originalEvent.dataTransfer.files;

         // If anything is wrong with the dropped files, exit.
         if (files && files.length > 0) {

            var count = files.length;

            // Only call the handler if 1 or more files was dropped.
            if (count > 0) {
               this.handleFiles(files);
            }

         }

      },

      render: function () {

         var source = $("#media_upload_template").html();
         var template = Handlebars.compile(source);
         var html = template({uploadMessage:"Upload it!"});

         $(this.el).append(html);


      }
   });

}(window));