(function(window, $, Backbone) {
   window.techsmith = window.techsmith || {};

   var defaultProfileImage = '/Content/Images/profile-avatar-default.png';
   window.techsmith.AccountWidget = Backbone.View.extend({
      events: {
         "click .account-widget-toggle" : "toggleWidget"
      },
      initialize: function () {
          this.widgetIsShown = false;
          this.updateProfileInformation(this.model.techSmithId);
      },
      toggleWidget: function(e) {
         e.preventDefault();
         if (this.widgetIsShown) {
            this.$el.find('.account-widget-container').fadeOut(200);
         } else {
            this.$el.find('.account-widget-container').fadeIn(200);
         }
         this.widgetIsShown = !this.widgetIsShown;
      },
      updateProfileInformation: function (techSmithId) {
          var that = this;
           $.ajax({
               type: 'GET',
               url: that.model.accountPageUrl + '/api/v1/profiles/' + techSmithId + '/default',
               success: function (data) {
                  if (data.displayName) {
                     that.setProfileWidgetDisplayName(data.displayName);
                  } else {
                     that.setProfileWidgetDisplayName('Unknown User');
                  }

                  if (data.profileImageUrl) {
                     that.setProfileWidgetImage(data.profileImageUrl);
                  } else {
                     that.setProfileWidgetImage(defaultProfileImage);
                  }
                  
               },
               error: function() {
                  that.setProfileWidgetDisplayName('Unknown User');
                  that.setProfileWidgetImage(defaultProfileImage);
               }
          });
      },
      setProfileWidgetDisplayName: function (name) {
          this.$el.find('.account-widget-body-details-name').text(name);
      },
      setProfileWidgetImage: function (profileImageUrl) {
         var img = new Image();
         img.onload = function () {
            $('.account-profile-small-image').attr('src', profileImageUrl);
            $('.account-profile-large-image').attr('src', profileImageUrl);
         };
         img.onerror = function() {
            $('.account-profile-small-image').attr('src', defaultProfileImage);
            $('.account-profile-large-image').attr('src', defaultProfileImage);
         }
         img.onabort = function() {
            $('.account-profile-small-image').attr('src', defaultProfileImage);
            $('.account-profile-large-image').attr('src', defaultProfileImage);
         }

         img.src = profileImageUrl;
      }
   });
}(window, $, Backbone));