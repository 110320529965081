(function (window, Handlebars, Backbone) {
   window.techsmith = window.techsmith || {};
   window.techsmith.ScrollToElementButtonView = Backbone.View.extend({
      tagName: 'div',
      className: 'back-to-top-button',
      events: {
         'click': 'onClick'
      },
      initialize: function (options) {
         _(this).bindAll('onScroll');
         this.template = Handlebars.templates.scrollToElementButton;
         this.labelText = options.labelText;
         this.focusSelector = options.focusSelector;
         this.threshold = parseInt(options.threshold);
         this.disable();
      },
      enable: function() {
         $(window).on('scroll.scrollToElement', this.onScroll);
         this.onScroll();
      },
      disable: function () {
         $(window).off('scroll.scrollToElement');
         this.$el.fadeOut();
      },
      close: function() {
         $(window).off('scroll.scrollToElement');
      },
      onScroll: function () {
         if ($(window).scrollTop() > this.threshold) {
            this.$el.fadeIn();
         } else {
            this.$el.fadeOut();
         }
      },
      onClick: function () {
         if (this.focusSelector && this.focusSelector.length > 0) {
            var focusEl = $(this.focusSelector);
            $(document).scrollTo(focusEl, { offset: -($(window).height() / 2), axis: 'y', duration: 500 });
         } else {
            $('body,html').animate({
               scrollTop: 0
            }, 500);
         }
         window.techsmith.AnalyticsWrapper.recordEventWithoutBounce('Reports Page, Viewer Tab', 'Clicked on Return Button');
         return false;
      },
      render: function () {
         var html = this.template({ labelText: this.labelText });
         this.$el.html(html);
         this.$el.hide();
         return this;
      },
   });
}(window, Handlebars, Backbone));