window.techsmith = window.techsmith || {};
techsmith.gaq = typeof _gaq == "undefined" ? undefined : _gaq;
(function (window, gaq) {
   window.techsmith = window.techsmith || {};
   window.techsmith.AnalyticsWrapper = (function () {
      return {
          recordEventWithoutBounce: function (category, label, action, value) {
             if(gaq) {
                gaq.push(['_trackEvent', category, label, action, value, true]);
             }
         },
          setCrossDomainLinks: function () {
            if (gaq === undefined) {
               return;
            }
            var hasCrossDomainCapableAnalytics = gaq.length ? false : true;
            var currentDomain = document.domain;
            var crossDomains = ['de.techsmith.', '.techsmith.de/', 'jp.techsmith.', '.techsmith.co.jp', '.tsc-', '.custhelp.com'];
            $.each(crossDomains, function () {
               var singleDomainLink = this.toString();
               var crossDomainLinks = $("a[href*='" + singleDomainLink + "']").not(".not-cross-domain, a[href*='" + currentDomain + "']");
               crossDomainLinks.on( 'click', function (e) {
                   if (hasCrossDomainCapableAnalytics) {
                      gaq.push(['_link', $(this).attr('href')]);
                      return false;
                  }
               });
            });
         }
      };
   }() );
}(window, techsmith.gaq))