(function (window, alertify, $) {
   window.techsmith = window.techsmith || {};
   window.techsmith.notify = window.techsmith.notify || {};

   var defaultTenSecondTimeout = 10000;

   window.techsmith.notify.showPageLoadError = function (errorMessage, el) {
      var messageModel = new techsmith.MessageModel({ message: errorMessage });
      var messageView = new techsmith.MessageView({ model: messageModel });
      el.append(messageView.render().el);
   };

   window.techsmith.notify.log = function (message) {
      alertify.log(message, "", 0);
   };
   window.techsmith.notify.logWithTimeout = function (message, timeoutInMilliseconds) {
      timeoutInMilliseconds = timeoutInMilliseconds || defaultTenSecondTimeout;

      alertify.log(message, "", timeoutInMilliseconds);
   };

   window.techsmith.notify.success = function (message) {
      alertify.success(message, 0);
   };

   window.techsmith.notify.successWithTimeout = function (message, timeoutInMilliseconds) {
      timeoutInMilliseconds = timeoutInMilliseconds || defaultTenSecondTimeout;

      alertify.success(message, timeoutInMilliseconds);
   };
   
   window.techsmith.notify.error = function (message) {
      alertify.error(message, 0);
   };

   window.techsmith.notify.errorWithTimeout = function (message, timeoutInMilliseconds) {
      timeoutInMilliseconds = timeoutInMilliseconds || defaultTenSecondTimeout;

      alertify.error(message, "", timeoutInMilliseconds);
   };

   window.techsmith.notify.alert = function (message) {
      alertify.set({
         labels: {
            ok: window.techsmith.strings.common.ok
         }
      });
      alertify.alert(message);
   };

   window.techsmith.notify.confirm = function (message, okCallback, cancelCallback, buttonLabels) {
      if (typeof buttonLabels === "undefined") {
         buttonLabels = {
            ok: window.techsmith.strings.common.ok,
            cancel: window.techsmith.strings.common.cancel
         };
      }

      alertify.set({
         labels: {
            ok: buttonLabels["ok"],
            cancel: buttonLabels["cancel"]
         }, buttonReverse: true
      });
      
      alertify.confirm(message, function(e) {
         if (e) {
            if ($.isFunction(okCallback)) {
               okCallback();
            }
         } else {
            if ($.isFunction(cancelCallback)) {
               cancelCallback();
            }
         }
      });
   };

   window.techsmith.notify.alertWithHeader = function (header, message) {
       window.techsmith.notify.alert("");
       var htmlMessage = "<p class=\"confirmHeader\">" + header + "</p><p class=\"confirmMessage\">" + message + "</p>";
       $('.alertify-message').append($.parseHTML(htmlMessage));
   };

    window.techsmith.notify.confirmWithHeader = function (header, message, okCallback, cancelCallback, buttonLabels) {
      window.techsmith.notify.confirm("", okCallback, cancelCallback, buttonLabels);
      var htmlMessage = "<p class=\"confirmHeader\">" + header + "</p><p class=\"confirmMessage\">" + message + "</p>";
      $('.alertify-message').append($.parseHTML(htmlMessage));
   };

}(window, alertify, jQuery))