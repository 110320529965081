/// <reference path="~/Scripts/vendor/"/>
(function (window, Handlebars) {
   "use strict";
   window.LegacyUploadMediaView = Backbone.View.extend({
      events: {
         'change input[type="file"]' : 'fileSelected'
      },
      initialize: function () {
         _(this).bindAll('render', 'fileSelected');
         this.render();
      },
      render: function () {
         var template = Handlebars.templates.legacyMediaUpload;
         var html = template({});

         $(this.el).append(html);
      },
      fileSelected: function(e) {
         $('#uploadError').remove();

         var fileSizeInBytes = 0;
         var maxFileSizeInBytes = 104857600;
         var uploadControl = this.$('input[type="file"]');
         
         if (window.ActiveXObject) {
            try {
               var fso = new ActiveXObject("Scripting.FileSystemObject");
               var file = fso.getFile(uploadControl.val());
               fileSizeInBytes = file.size;
            }
            catch(e) {
               // browser security settings are probably blocking ActiveX
            }
         }
         else {
            fileSizeInBytes = e.target.files[0].size;
         }
         
         if( fileSizeInBytes > maxFileSizeInBytes ) {
            uploadControl.replaceWith(uploadControl.clone().val(''));
            $(this.el).before($('<h3>').prop('id', 'uploadError').text('Files cannot be over 100MB, please try again.').css('color', '#A80000'));
         }
      }
   });
}(window, Handlebars));